'use client';

import { SessionProvider } from 'next-auth/react';

interface Props {
  children: React.ReactNode,
  remember: boolean | null | undefined;
}

export default function ClientProvider({ children, remember }: Props) {
  return (
    <SessionProvider
      refetchInterval={remember ? 24 * 50 * 50 : 0}
      refetchOnWindowFocus
    >
      {children}
    </SessionProvider>
  );
}
